let config = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "forwood-rossing-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-rossing-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-east-1",
        URL: "https://xca3v23mvzgpxcsdndukb5xrfi.appsync-api.us-east-1.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://8bwj9fm8hh.execute-api.us-east-1.amazonaws.com/env",
        USER_TOKEN_URL: "https://jglat4kar7.execute-api.us-east-1.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.rossing.forwoodsafety.com",
        WEBSOCKET: "wss://ywkefmoqw1.execute-api.us-east-1.amazonaws.com/staging"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_OIsqM91LN",
        IDENTITY_POOL_ID: "us-east-1:e98e2742-19cf-471a-8a1b-ebb025305eaa",
        USERPOOL_HOSTED_DOMAIN: "forwood-rossing-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.rossing.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.rossing.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::479280509747:role/staging-CA"
    },
    samlProvider: {
      NAME: 'Rossing',
      SUPPORTED_LIST: 'Rossing,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.36.1",
        HOSTNAME: "id.staging.rossing.forwoodsafety.com",
        ENV_NAME: "staging",
        COOKIE_DOMAIN: ".staging.rossing.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "434adf68-536a-41c0-b189-b1b4afd3765f",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.rossing.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
